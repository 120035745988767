import * as React from 'react';

// types
import type { VacancyType } from '../../types';

// components
import { Anchor, Card, Flex, Group, Image, MantineTheme, Stack, Text, createStyles, useMantineTheme } from '@mantine/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { FaChevronRight } from 'react-icons/fa';

interface Props {
    vacancy: VacancyType;
}

const useStyles = createStyles((theme: MantineTheme) => ({
    hideAnchor: {
        [theme.fn.smallerThan('md')]: {
            display: 'none'
        }
    },
    responsiveImage: {
        [theme.fn.smallerThan('md')]: {
            width: '50vw',
            height: '40vw',
        }
    },
    vacancyTitle: {
        marginBottom:0,
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            textAlign: 'center',
            lineHeight: 1.2,
            wordBreak: 'break-word'
        }
    },

    vacancyDetail: {
        [theme.fn.smallerThan('md')]: {
            display:'none'
        },

    },
    vacancyDetailResponsive: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            textAlign: 'center',
            lineHeight: 1.3
        },
        [theme.fn.largerThan('md')]: {
            display:'none'
        }
    }
}))

const VacancyCard = ({ vacancy }: Props) => {
    const { classes } = useStyles();
    const theme = useMantineTheme();

    return (
        <Card
            p={0}
            my={30}
            shadow='sm'
            component={Link}
            to={`/jobs/${vacancy.slug}`}
            sx={(theme) => ({
                width: '100%',
                img: {
                    filter: 'grayscale(100%)',
                },
                transition: 'all 0.2s linear',

                '&:hover': {
                    boxShadow: theme.shadows.md,
                    img: {
                        filter: 'grayscale(30%)',
                    },
                }
            })}>

            <Flex direction={{ base: 'row', md: 'row' }}

                align={'center'}
                gap={{ base: 'xs', sm: 'lg' }}
                justify={{ sm: 'center' }}>
                <Card.Section className={classes.responsiveImage}>
                    {
                        vacancy.image ?
                            <GatsbyImage
                                className={classes.responsiveImage}
                                image={vacancy.image.gatsbyImageData}
                                alt={vacancy.title} />
                            :
                            <Image
                                src="https://images.unsplash.com/photo-1511376777868-611b54f68947?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                                alt={vacancy.title}
                                width={200} />
                    }
                </Card.Section>
                <Flex justify="space-between" style={{ flexGrow: 10 }} direction={{ base: 'column', md: 'row' }}>
                    <Stack justify="center" px={30}>
                        <Text weight={700} size="xl" className={classes.vacancyTitle}>
                            {vacancy.title}
                        </Text>

                        <Text  dangerouslySetInnerHTML={{ __html: vacancy.details.replace('|', '<br>') }} color="dimmed" size="sm" className={classes.vacancyDetailResponsive}>
                        {/* {vacancy.details.replace('|', '<br>')} */}
                        </Text>

                        <Text color="dimmed" size="sm" className={classes.vacancyDetail}>
                        {vacancy.details}
                        </Text>

                    </Stack>
                    <Anchor p="lg" display="flex" align="center" component='span'>
                        <Group className={classes.hideAnchor}>
                            Bekijk vacature
                            <FaChevronRight size={12} />
                        </Group>
                    </Anchor>
                </Flex>
            </Flex>

        </Card>
    );
};

export default VacancyCard;



