import { EmployeeType } from "../types";

// Shuffles the list of employees into a random order.
export const shuffleList = (employees: EmployeeType[]): EmployeeType[] => {
    // Copy the list of employees
    const result = employees.slice();
    let remaining = result.length;
    while (remaining) {
        let index = Math.floor(Math.random() * remaining--);
        let temp = result[remaining];
        result[remaining] = result[index];
        result[index] = temp;
    };
    return result;
};