import * as React from 'react';

// components
import { Container, Flex, Grid, MantineTheme, SimpleGrid, Space, Text, Title, createStyles, useMantineTheme } from '@mantine/core';
import { getFeaturedEmployees } from '../../services/employees/featured-employees';

import { sampleSize } from '../../util/sample-size';
// types
import type { EmployeeType } from '../../types';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = createStyles((theme: MantineTheme) => ({
    titleAlign: {
        lineHeight: 1.3,
        [theme.fn.smallerThan('md')]: {
            display: 'flex',
            justifyContent: 'center',
            fontSize: "calc(40px + 1vw)",
        }
    },

    textAlign: {
        [theme.fn.smallerThan('md')]: {
            textAlign: 'center',
            fontSize: "calc(34px + 1vw)",
            lineHeight: 1.3
        },
    },

    textBreak: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(34px + 1vw)",
            lineHeight: 1.5,
        }
    },

    textBreakLarge: {
        [theme.fn.smallerThan('md')]: {
            display: 'none'
        }
    },

    textBreakSmall: {
        [theme.fn.smallerThan('md')]: {
            lineBreak: 'normal',
            textAlign:'center',
            fontSize: "calc(34px + 1vw)",
            lineHeight: 1.5,
            wordBreak: 'normal'
        },
        [theme.fn.largerThan('md')]: {
            display: 'none'
        }
    },

    imageSize: {
        [theme.fn.smallerThan('md')]: {
            transform: 'scale(2.2)',
            marginTop: 120,
            marginBottom: 95,
            paddingTop: 70,
            paddingBottom: 70,
        }
    }
}))

const HeroJobs = () => {

    const { classes } = useStyles();
    const theme = useMantineTheme()
    const employees: EmployeeType[] = sampleSize(getFeaturedEmployees(), 8);

    return (

        <Container py='5rem' size="lg">
            <Grid>
                <Grid.Col offset={0} offsetMd={0} offsetSm={2} md={6} sm={8} span={6}>
                    <Title order={1}>
                        <Text
                            className={classes.titleAlign}
                            size={40}
                            color='gray.8'>
                            Onze vacatures
                        </Text>
                    </Title>
                    <Space h="xl" />
                    <Text size={20} color='gray.7' mt="xl" className={classes.textAlign}>
                        <span className={classes.textBreak}>Steengoed softwaretalent?</span>
                        Stap aan boord en maak mee het verschil binnen een stevig groeiende software partner.
                        Samen helpen we onze klanten vooruit door het uitleven van onze passie.
                        <span className={classes.textBreakLarge}>Tot binnenkort!</span>
                    </Text>
                    <Text className={classes.textBreakSmall}>Tot binnenkort!</Text>
                </Grid.Col>
                <Grid.Col offset={3.3} offsetMd={1} span={5}>
                    <SimpleGrid cols={4} spacing="xs" className={classes.imageSize}>
                        {
                            employees.map((employee: EmployeeType, index) => {
                                return (
                                    <div key={index}>
                                        <GatsbyImage
                                            alt={employee.name}
                                            image={employee.photo.gatsbyImageData}
                                            style={{
                                                width: 100,
                                                height: 100,
                                            }} />
                                    </div>
                                )
                            })
                        }
                    </SimpleGrid>
                </Grid.Col>
            </Grid>
        </Container>
    );
};

export default HeroJobs;