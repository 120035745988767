import * as React from "react";
import { getAllVacancies } from "../../services/vacancies/all-vacancies";

// types
import type { VacancyType } from "../../types";

// components
import { Container, MantineTheme, useMantineTheme } from "@mantine/core";
import VacancyCard from "./vacancy-card";

const OverviewVacancies = () => {
	const vacancies: VacancyType[] = getAllVacancies();
    const theme: MantineTheme = useMantineTheme();
	return (
        <div style={{
            backgroundColor: theme.colors.gray[1],
        }}>
            <Container py="lg" size="lg">
                {vacancies.map((vacancy) => (
                        <VacancyCard key={vacancy.id} vacancy={vacancy} />
                ))}
            </Container>
        </div>

	);
};

export default OverviewVacancies;
