import { graphql, useStaticQuery } from 'gatsby';
import type { EmployeeType } from '../../types';

type GraphQLResult = {
	allContentfulEmployee: {
		nodes: EmployeeType[];
	};
};

export const getFeaturedEmployees = (): EmployeeType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query featuredEmployees {
            allContentfulEmployee(filter: {featured: {eq: true}}) {
                nodes {
                    id
                    name
                    photo {
                        gatsbyImageData(
                            width: 400,
                            height: 400
                        )
                    }
                }
            }
        }
    `);
    
    return data.allContentfulEmployee.nodes;
};
