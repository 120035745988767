import * as React from 'react';
import CareerValues from '../../components/home-page/career-values';
import JobsEmployeeBanner from '../../components/jobs-page/jobs-employee-banner';
import HeroJobs from '../../components/jobs-page/hero-jobs';
import OverviewVacancies from '../../components/jobs-page/overview-vacancies';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ApplySpontaneously from '../../components/jobs-page/apply-spontaneously';

const JobsPage = () => {
    return (
        <Layout>
            <HeroJobs/>
            {/* <JobsEmployeeBanner/> */}
            <OverviewVacancies/>
            <ApplySpontaneously/>
        </Layout>
    );
};

export const Head = () => (
    <SEO title='Jobs'/>
);

export default JobsPage;