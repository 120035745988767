import { graphql, useStaticQuery } from 'gatsby';
import type { VacancyType } from '../../types';

type GraphQLResult = {
	allContentfulVacancy: {
		nodes: VacancyType[]
	};
};

export const getAllVacancies = (): VacancyType[] => {
    const data = useStaticQuery<GraphQLResult>(graphql`
        query allVacancies {
            allContentfulVacancy {
                nodes {
                    id
                    title
                    slug
                    details
                    image {
                        gatsbyImageData(
                            width: 200
                        )
                    }
                    content {
                        raw
                    }
                }
            }
        }
    `);
    
    return data.allContentfulVacancy.nodes;
};