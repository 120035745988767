import * as React from 'react';

// components
import { Button, Container, Grid, MantineTheme, Space, Text, Title, createStyles, useMantineTheme } from '@mantine/core';
import { TbBrandTelegram } from 'react-icons/tb';

// illustrations
import teamBuildingSVG from '../../images/illustrations/team-building.svg';

const useStyles = createStyles ((theme: MantineTheme) => ({
    fontSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
        }
    },
    buttonSize: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
            minHeight: '30%',
            height: 10,
            marginBottom:30
        }
    },
    imgSize: {
        width: 300,
        [theme.fn.smallerThan('md')]: {
            width: 400,
            paddingTop: theme.spacing.xl
        }
    },
}))

const ApplySpontaneously = () => {
    const { classes } = useStyles();
    const theme = useMantineTheme();
    return(
        <Container py='5rem' size="lg">
            <Grid>
                {/* Adjust Grid properties to align on smaller screens */}
                <Grid.Col offset={4} offsetSm={3} offsetMd={0} span={6}>
                    <Title order={1} my="xl">
                        <Text
                            size={40}
                            color='gray.8'
                            sx={{
                                lineHeight: 1.3,
                            }}
                            className={classes.fontSize}>
                            Geen passende vacature,
                            wel een passend profiel?
                        </Text>
                    </Title>
                    <Space h="xl" />
                    <Button leftIcon={<TbBrandTelegram size={25} />} mt="xl"
                             component="a"
                             href="mailto:hello@qmino.com"
                             className={classes.buttonSize}>
								Solliciteer spontaan
							</Button>
                </Grid.Col>
                {/* Adjust Grid properties to align on smaller screens */}
                <Grid.Col offset={4} offsetMd={2} span={4}>
                    <img
                        src={teamBuildingSVG}
                        alt={'Solliciteer spontaan'}
                        className={classes.imgSize}
                    />
                </Grid.Col>
            </Grid>
        </Container>
    );
};

export default ApplySpontaneously;
